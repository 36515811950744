<template
  src="@/../../../templates/brand/components/templates/shop/ReservationDetails.html"
>
</template>

<script>
// imports
import _ from 'lodash/fp'
import { mapActions, mapState, mapGetters } from 'vuex'
import loader from '@/components/Loader.vue'
import accounting from 'accounting'

export default {
  name: 'Reservation-details',
  data() {
    return {
      reservation: {
        price: {},
        shipping: { address: {} },
        billing: { address: {} },
        shippingService: {}
      },
      referenceItems: [],
      deletions: [],
      // program: {},
      dirty: {},
      deleted: {},
      loading: false
    }
  },
  components: { loader },
  computed: {
    ...mapState({
      program: ({ Collection }) => Collection.program,
      user: ({ App }) => App.user
    }),
    ...mapGetters({
      appconfig: 'const'
    }),
    canEditReservation() {
      return (
        this.user.profile.extradata.canEditReservation &&
        this.program.settings.communityVolume
      )
    },
    subtotal() {
      if (!this.canEditReservation) return 0
      return _.reduce(
        (sum, i) => sum + this.adjustedPrice(i).subtotal,
        0,
        this.reservation.items
      )
    },
    reservationDirty() {
      return _.keys(this.dirty).length || this.deletions.length
    },
    serviceDescription() {
      if (
        _.isEmpty(this.reservation.price) ||
        this.reservation.price.shippingTBD ||
        this.reservation.shippingService.shippingTBD
      )
        return 'TBD'
      if (
        ~['included', 'flatrate', 'tiered'].indexOf(
          this.reservation.shippingService.type
        )
      ) {
        return this.reservation.shippingService.type
      }
      return this.reservation.shippingService.carrier === 'UPS'
        ? this.appconfig.locale[this.$i18n.locale].upsServiceTypeMap[
            this.reservation.shippingService
          ]
        : this.reservation.shippingService.rate.service.name
    }
  },
  // component methods
  methods: {
    ...mapActions(['profileUpdate', 'setProgram', 'getProgram']),
    adjustedPrice(item) {
      // if (!this.program.settings.communityVolume) {
      //   return item
      // }
      if (!this.program.summary || !this.program.settings.communityVolume)
        return {}
      const _tiers = _.sortBy(a => a.volume, item.product.adjustments)
      let _vol = 0
      // if editable, dynamically normalize volume to current program volume - user's existing volume + user's edited volume
      if (this.canEditReservation) {
        _vol = this.program.summary[
          item.product.relatedBy === 'unit'
            ? item.product.SKU
            : item.product.familyKey +
              ((item.product.variationField &&
                item.product.variationField.key) ||
                item.product.variationKey ||
                '')
        ].volume
        _vol =
          _vol -
          this.referenceItems.find(i => i.product.SKU === item.product.SKU)
            .quantity +
          item.quantity
      } else {
        _vol = this.reservation.extradata.summary[
          item.product.relatedBy === 'unit'
            ? item.product.SKU
            : item.product.familyKey +
              ((item.product.variationField &&
                item.product.variationField.key) ||
                item.product.variationKey ||
                '')
        ].volume
      }

      if (!_tiers.length || _vol < _tiers[0].volume) {
        return {
          val: item.product.price + (item.customize ? item.customUnitPrice : 0),
          tier: -1,
          subtotal:
            item.quantity *
            (item.product.price + (item.customize ? item.customUnitPrice : 0))
        }
      }

      if (_vol > _tiers[_tiers.length - 1].volume) {
        return {
          val:
            _tiers[_tiers.length - 1].value +
            (item.customize ? item.customUnitPrice : 0),
          tier: _tiers[_tiers.length - 1].volume,
          subtotal:
            item.quantity *
            (_tiers[_tiers.length - 1].value +
              (item.customize ? item.customUnitPrice : 0))
        }
      }
      let _next = _.findIndex(i => i.volume >= _vol + 1)(_tiers)
      _next > 0 ? (_next -= 1) : _next
      return {
        val: _tiers[_next].value + (item.customize ? item.customUnitPrice : 0),
        tier: _tiers[_next].volume,
        subtotal:
          item.quantity *
          (_tiers[_next].value + (item.customize ? item.customUnitPrice : 0))
      }
    },
    productName(product) {
      const _prod = this.reservation.products.find(i => i.SKU === product.SKU)
      return _prod.content[this.$i18n.locale].name
    },
    productImage(product) {
      const _prod = this.reservation.products.find(i => i.SKU === product.SKU)
      return _prod.images.find(i => i.default) || _prod.images[0]
    },
    currencyLocale(val) {
      return accounting.formatMoney(
        val,
        this.appconfig.locale[this.$i18n.locale].currency
      )
    },
    // serviceDescription (service) {
    //   console.info(' :::: what service', this.reservation)
    //   if (~['included', 'flatrate', 'tiered'].indexOf(this.reservation.service.type)) {
    //     return this.reservation.shippingService.type
    //   }
    //   return service.carrier === 'UPS'
    //     ? this.appconfig.locale[this.$i18n.locale].upsServiceTypeMap[service]
    //     : service.rawdata.service.name
    // },
    removeItem(item) {
      this.deletions.push(item.product.SKU)
    },
    restoreItem(item) {
      this.deletions = _.remove(i => i === item.product.SKU, this.deletions)
    },
    deleteReservation() {
      this.$vex.dialog.confirm({
        message: 'Are you sure you want to delete this reservation?',
        callback: value => {
          if (value) {
            this.loading = true
            this.$http
              .post(
                `/programs/${this.program._id}/reservations/${this.reservation._id}/remove`,
                { reservation: this.reservation._id }
              )
              .then(() => {
                const _profile = this.user.profile
                _profile.extradata.canEditReservation = false
                this.profileUpdate({ user: this.user, profile: _profile })
                console.log('Successfully destroyed the planet.')
                this.$router.push('/myaccount/reservations')
              })
          } else {
            this.loading = false
          }
        }
      })
    },
    updateReservation() {
      this.loading = true
      const _reservation = _.assign({}, this.reservation)
      if (this.deletions.length) {
        _reservation.items = _.remove(
          i => ~this.deletions.indexOf(i.product.SKU),
          _reservation.items
        )
      }
      if (!_reservation.items.length) {
        this.deleteReservation()
        return
      }
      _reservation.price.subtotal = this.subtotal
      this.$http
        .post(
          `/programs/${this.program._id}/reservations/${this.reservation._id}`,
          { reservation: _reservation }
        )
        .then(res => {
          console.info('   === got res', res)
          this.loading = false
          this.reservation = res.data.data
          const _profile = this.user.profile
          _profile.extradata.canEditReservation = false
          this.profileUpdate({ user: this.user, profile: _profile })
        })
    },
    isDirty(item) {
      const _dirty = _.assign({}, this.dirty)
      // const _res = this.reservation.items.find(i => i.product.SKU === item.product.SKU);
      const _ref = this.referenceItems.find(
        i => i.product.SKU === item.product.SKU
      )
      if (
        item.quantity !== _ref.quantity ||
        (item.customize !== _ref.customize &&
          (item.customize || _ref.customize))
      ) {
        _dirty[item.product.SKU] = true
      } else {
        delete _dirty[item.product.SKU]
      }
      this.dirty = _.assign({}, _dirty)
    }
  },
  created() {
    // debugger;
    this.$http
      .get(`/orders/${this.$route.params.reservationid}?products=true`)
      .then(res => {
        _.each(i => {
          this.referenceItems.push(_.assign({}, i))
        }, res.data.data.items)
        this.reservation = _.assign({}, res.data.data)
        this.reservation.items = _.sortBy(
          ['product.SKU'],
          this.reservation.items
        )
        // return this.$http.get(`/programs/${res.data.data.items[0].program}`)
        return this.setProgram(this.reservation.extradata.collection.id)
      })
      .then(pro => {
        console.info(' === set program?'.pro)
        if (!pro)
          this.getProgram({
            id: this.reservation.extradata.collection.id,
            key: 'id'
          })
      })
  }
}
</script>

<style
  src="@/../../../templates/brand/components/styles/shop/ReservationDetails.css"
></style>
